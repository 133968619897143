import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import logo from '../../assets/images/logo-08-final-1.png';

//
import axiosInstance from '../../axiosConfig'; // Ensure the correct relative path
import { setCookie } from '../../authUtils'; // Ensure the correct relative path
import { jwtDecode } from 'jwt-decode';
import { getCookie, deleteCookie } from '../../authUtils'; // Import getCookie function
//

// import Loading from "../widget/Loading";
import Header from "../pages/Header";

import NotificationModal from '../modals/NotificationModal';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import CollectionsIcon from '@mui/icons-material/Collections';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ShopIcon from '@mui/icons-material/Shop';
import PaidIcon from '@mui/icons-material/Paid';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


import Loading from "../widget/Loading";


export default function ProfileDashboardPage({ options, cart, setSiteLanguage, addToCart, updateCart, removeCartItem, removeAllCartItem }) {
    const navigate = useNavigate();
    const location = useLocation();


    const [isDataloading, setIsDataLoading] = useState(false);
    const [isUserOrdersDataLoading, setIsUserOrdersDataLoading] = useState(true);
    const [userOrdersData, setUserOrdersData] = useState([]);



    const logout = () => {
      deleteCookie('valorhijab-user-token'); 
      deleteCookie('valorhijab-userDetails'); 
      navigate('/');
    }

    
  const storedUserDetails = getCookie('valorhijab-userDetails');
  const userDetails = storedUserDetails ? JSON.parse(storedUserDetails) : null;
  // alert(JSON.stringify(JSON.parse(storedUserDetails), null, 2));
  // alert(JSON.stringify(userDetails.Delivery_Address, null, 2));

  const [userData, setUserData] = useState({
    userCustomerId: userDetails.Customer_Id ?? '',
    userFirstname: userDetails.Firstname ?? '',
    userLastname: userDetails.Lastname ??'',
    userEmail: userDetails.Email ??'',
    userPhoneNumber: userDetails.Phone_Number ??'',

    userOldPassword: '',
    userNewPassword: '',
    userConfirmPassword: ''
});



const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
const [newPasswordVisible, setNewPasswordVisible] = useState(false);
const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);



    
    useEffect(() => {
        const fetchData = async () => {
        await Promise.all([handleUserOrders()]);
        };
        
        fetchData();
      }, []);

      const handleUserOrders = async () => {    

       
        setIsUserOrdersDataLoading(true);
        try {
          
            const requestData = {   
                email: userData.userEmail
              };

              // alert(JSON.stringify(requestData), null, 2);
            var endpoint = process.env.REACT_APP_API_SERVER_URL + process.env.REACT_APP_USER_ORDERS;
    

            const response = await axiosInstance.post(endpoint, requestData, {
            headers: {
              "Content-Type": "application/json",
            },
          });
    
          setIsUserOrdersDataLoading(false);
          // alert(JSON.stringify(response.data, null, 2));
        //   return;
    
          if (response.data.status) {
            alert("dashboard-products " + JSON.stringify(response.data, null, 2));
          
            // Store the retrieved data in state variables

            setUserOrdersData(response.data.ordersData);
            

          } else {
            // alert("error: " + response.data.message);
            openNotificationModal(false, "Valor Hijab - User Orders", response.data.message);
          }

        } catch (error) {
            setIsUserOrdersDataLoading(false);
        //   alert("error: " + error);
          openNotificationModal(false, "Valor Hijab - User Orders", error);
        }
      };







  
    

    const [activeTab, setActiveTab] = useState(0);
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
    const [selectedAddress, setSelectedAddress] = useState({});
    const [address, setAddress] = useState({
        address1: userDetails.Delivery_Address.address1 ?? '',
        address2: userDetails.Delivery_Address.address2 ?? '',
        city: userDetails.Delivery_Address.city ?? '',
        postalCode: userDetails.Delivery_Address.postalCode ?? '',
        state: userDetails.Delivery_Address.state ?? '',
        country: userDetails.Delivery_Address.country ?? '',
    });

    const addressEmpty = useState({
        address1: '',
        address2: '',
        city: '',
        postalCode: '',
        state: '',
        country: '',
    });







    //notification modal
    const [notificationType, setNotificationType] = useState(false);
    const [notificationTitle, setNotificationTitle] = useState("");
    const [notificationMessage, setNotificationMessage] = useState("");
    const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
    const openNotificationModal = (type, title, message) => {
      setNotificationType(type);
      setNotificationTitle(title);
      setNotificationMessage(message);
  
      setIsNotificationModalOpen(true);
    };
    const closeNotificationModal = () => {
      setIsNotificationModalOpen(false);
    };
    //notification modal





    

  
          const updateCustomerDeliveryAddressIndex = async (addressIndex) => {

              setIsDataLoading(true);
  // alert(address1);
  
                  const requestData = {
                      addressIndex: addressIndex,
                      customerId: userDetails.Customer_Id,
                      // address: address
                      address: {
                          address1: address1,
                          address2: address2,
                          city: city,
                          postalCode: postalCode,
                          state: state,
                          country: country,
                      }
                  };
      
                  // alert(JSON.stringify(requestData, null, 2));
      // return;
                  try {
                    var endpoint = process.env.REACT_APP_API_SERVER_URL + process.env.REACT_APP_UPDATE_USER_ADDRESS;
                    // alert(endpoint);

                    const response = await axiosInstance.post(endpoint, requestData, {
                        //   const response = await axios.post(process.env.REACT_APP_API_SERVER_URL + '/response/afr-update-customer-delivery-address-index.php', requestData, {
                              //params: { uid: uid },
                            headers: {
                              "Content-Type": "application/json",
                              //Authorization: `Bearer ${token}`,
                            },
                          });
              
                          setIsDataLoading(false);
                          // alert(JSON.stringify(response.data, null, 2));
                          if (response.data.status) {
  
                              //update local cookie user info 
  // const token = response.data.token;
          // localStorage.setItem('token', response.data.token);
          setCookie('valorhijab-userDetails', JSON.stringify(response.data.user), 1);
  
          // Update userDetails with new values
          setAddress(response.data.user.Delivery_Address);
  
  
                              //alert("update-address " + JSON.stringify(response.data, null, 2));
                              setActiveTab(5);
      
                          } else {
                              alert("error1: " + response.data.message);
                          }
                  } 
                  catch (error) {
                      setIsDataLoading(false);
                      alert("error2: " + error);
                  }
              };
  
              
              const updateCustomerAccountDetails = async () => {
                  // alert(JSON.stringify("!", null, 2));
                  // return;
                          setIsDataLoading(true);
                  
                  
                              const requestData = {
                                  customerId: userData.userCustomerId,
                                  firstname: userData.userFirstname,
                                  lastname: userData.userLastname,
                                  email: userData.userEmail,
                                  phonenumber: userData.userPhoneNumber,
                              };
                  
                  //             alert(JSON.stringify(requestData, null, 2));
                  // return;
                              try {
                                var endpoint = process.env.REACT_APP_API_SERVER_URL + process.env.REACT_APP_UPDATE_USER_DATA;
    

                                const response = await axiosInstance.post(endpoint, requestData, {
                                    //   const response = await axios.post(process.env.REACT_APP_API_SERVER_URL + '/response/afr-update-customer-account-details.php', requestData, {
                                          //params: { uid: uid },
                                        headers: {
                                          "Content-Type": "application/json",
                                          //Authorization: `Bearer ${token}`,
                                        },
                                      });
                          
                                      setIsDataLoading(false);
                                      // alert(JSON.stringify(response.data, null, 2));
                                      if (response.data.status) {
                  
                                          //update local cookie user info 
                  // const token = response.data.token;
                      // localStorage.setItem('token', response.data.token);;k;
                      setCookie('valorhijab-userDetails', JSON.stringify(response.data.user), 1);
                  
                      // Update userDetails with new values
                      // setAddresses(response.data.user.Address);
                  
                  
                                          //alert("update-address " + JSON.stringify(response.data, null, 2));
                                          setActiveTab(4);
                                          // alert(response.data.message);
                  
                                          
                            // setNotificationMessage(response.data.message);
                            // setShowNotificationModal(true);
                            openNotificationModal(true, "Valor Hijab", response.data.message);
                  
                                      } else {
                                        //   alert("error1: " + response.data.message);
                                          openNotificationModal(false, "Valor Hijab - Update Account", response.data.message);
                                      }
                              } 
                              catch (error) {
                                  setIsDataLoading(false);
                                //   alert("error2: " + error);
                                  openNotificationModal(false, "Valor Hijab - Update Account", error);
                              }
                          };


                          const updatePassword = async () => {
                            // Ensure userData object and passwords are properly checked
                            if (userData.userNewPassword === "" || userData.userConfirmPassword === "") {
                                openNotificationModal(true, "Valor Hijab", "New password and confirm password must not be empty.");
                                return;
                            }
                        
                            if (userData.userNewPassword !== userData.userConfirmPassword) {
                                openNotificationModal(true, "Valor Hijab", "New password and confirm password do not match.");
                                return;
                            }
                        
                            if (!userData.userOldPassword || userData.userOldPassword === "") {
                                openNotificationModal(true, "Valor Hijab", "Old password is required.");
                                return;
                            }
                        
                            // Proceed only if all validations pass
                            setIsDataLoading(true);
                        
                            const requestData = {
                                oldPassword: userData.userOldPassword,
                                newPassword: userData.userNewPassword,
                                confirmPassword: userData.userConfirmPassword,
                                customerId: userData.userCustomerId,
                            };
                        
                            try {
                                const endpoint = process.env.REACT_APP_API_SERVER_URL + process.env.REACT_APP_UPDATE_PASSWORD;
                        
                                const response = await axiosInstance.post(endpoint, requestData, {
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                });
                        
                                setIsDataLoading(false);
                                // alert(JSON.stringify(response.data, null, 2));
                        
                                if (response.data.status) {

                                    {
                                        userData.userOldPassword = "";
                                        userData.userNewPassword = "";
                                        userData.userConfirmPassword = "";
                                        userData.userCustomerId = "";
                                    }

                                    openNotificationModal(true, "Valor Hijab", response.data.message);
                                    setActiveTab(4);
                                } else {
                                    openNotificationModal(false, "Valor Hijab - Update Password", response.data.message);
                                }
                            } catch (error) {
                                setIsDataLoading(false);
                        
                                // Extract relevant error message
                                const errorMessage = error.response?.data?.message || error.message || "An unexpected error occurred";
                                openNotificationModal(false, "Valor Hijab - Update Password", errorMessage);
                            }
                        };
                        


                          const [isHoverWestNewIn, setIsHoverWestNewIn] = useState(false);
                          const handleHoverWestNewIn = () => { setIsHoverWestNewIn(true); };
                          const handleLeaveWestNewIn = () => { setIsHoverWestNewIn(false); };
                      
                      
                          const [photo, setPhoto] = useState(null);
                          // const [photo, setPhoto] = useState({});
                          const handlePhotoChange = (e) => {
                              const file = e.target.files[0];
                              //console.log("Selected file:", file);
                              if (file) {
                                  const reader = new FileReader();
                                  reader.onloadstart = () => {
                                      // console.log("File reading started...");
                                  };
                                  reader.onprogress = (event) => {
                                      // console.log(`File reading progress: ${Math.round((event.loaded / event.total) * 100)}%`);
                                  };
                                  reader.onload = (readerEvent) => {
                                      // console.log("File reading completed.");
                                      // console.log("Resulting data URL:", readerEvent.target.result);
                                      setPhoto(readerEvent.target.result);
                                  };
                                  reader.onerror = (error) => {
                                      // console.error("File reading error:", error);
                                  };
                                  reader.readAsDataURL(file);
                              } else {
                                //   alert("No file selected.");
                                  openNotificationModal(false, "Valor Hijab - Profile Photo", "No file selected");
                              }
                          };


    // Initialize address fields only when selectedAddress is set
    const [address1, setAddress1] = useState(selectedAddress?.deliveryAddress?.address1 || '');
    const [address2, setAddress2] = useState(selectedAddress?.deliveryAddress?.address2 || '');
    const [postalCode, setPostalCode] = useState(selectedAddress?.deliveryAddress?.postalCode || '');
    const [city, setCity] = useState(selectedAddress?.deliveryAddress?.city || '');
    const [state, setState] = useState(selectedAddress?.deliveryAddress?.state || '');
    const [country, setCountry] = useState(selectedAddress?.deliveryAddress?.country || '');

    useEffect(() => {
        setAddress1(selectedAddress?.deliveryAddress?.address1 || '');
        setAddress2(selectedAddress?.deliveryAddress?.address2 || '');
        setPostalCode(selectedAddress?.deliveryAddress?.postalCode || '');
        setCity(selectedAddress?.deliveryAddress?.city || '');
        setState(selectedAddress?.deliveryAddress?.state || '');
        setCountry(selectedAddress?.deliveryAddress?.country || '');
    }, [selectedAddress]);


    useEffect(() => {
        if (selectedAddress && selectedAddress.deliveryAddress) {
            setAddress({
                address1: selectedAddress.deliveryAddress.address1 || '',
                address2: selectedAddress.deliveryAddress.address2 || '',
                city: selectedAddress.deliveryAddress.city || '',
                postalCode: selectedAddress.deliveryAddress.postalCode || '',
                state: selectedAddress.deliveryAddress.state || '',
                country: selectedAddress.deliveryAddress.country || '',
            });
        }
    }, [selectedAddress]);



    const [showEdit, setShowEdit] = useState(false);


















    return (
        <div className='relative bg-valorHijabTheme h-full' style={{  }}>
        <Header  options={options} cart={cart} setSiteLanguage={setSiteLanguage} 
        addToCart={addToCart} updateCart={updateCart} removeCartItem={removeCartItem} 
        removeAllCartItem={removeAllCartItem} />


        





{/* Main Contents */}
<div 
// className=' bg-white md:px-4 lg:px-16 xl:px-24 2xl:px-64  md:py-4  pb-4 
//     flex flex-col  '
    >



<img 
    src={'https://www.valorhijab.com/api/v1/vh-images/Pleated.jpeg'} //{process.env.REACT_APP_API_SERVER_URL + "/vh-images/" + parsedProduct.Image_Url}
    alt='overlay' 
    className='absolute inset-0 w-full h-full object-cover opacity-20' 
  />






       
</div>





        
        


<div className='container-1410 mt-12 ' style={{  }}>
            <div className="flex w-full md:flex-row flex-col relative z-20">
                <div className="flex flex-col flex-grow bg-white md:mx-4 mb-4" style={{ flexBasis: '20%', height: '500px' }}>
                    {/* <div className='mx-2 bg-red-400'>Content for the first div (20%)</div> */}

                    <div className="sticky flex flex-col gap-2 p-4 text-sm top-12">
                <a
                    style={{ cursor: "pointer" }}
                    onClick={() => setActiveTab(0)}
                    className={`flex items-center justify-between pl-4 py-2 hover:text-black ${activeTab === 0 ? 'bg-gray-200' : ''}`}
                >
                    <div className='flex items-center '>
                        <AccountCircleIcon />
                        <span className="pl-4">Account</span>
                    </div>
                    {activeTab === 0 ? <KeyboardArrowRightIcon className='p-1'/> : ''}
                </a>
                
                {/* <a
                    style={{ cursor: "pointer" }}
                    onClick={() => setActiveTab(1)}
                    className={`flex items-center justify-between pl-4 py-2 hover:text-black ${activeTab === 1 ? 'bg-gray-200' : ''}`}
                >
                    
                    <div className='flex items-center '>
                        <CollectionsIcon />
                        <span className="pl-4">Collections</span>
                    </div>
                    {activeTab === 1 ? <KeyboardArrowRightIcon className='p-1'/> : ''}
                </a> */}
                {/* <a
                    style={{ cursor: "pointer" }}
                    onClick={() => setActiveTab(2)}
                    className={`flex items-center justify-between pl-4 py-2  hover:text-black ${activeTab === 2 ? 'bg-gray-200' : ''}`}
                >
                    
                    <div className='flex items-center '>
                        <LocalActivityIcon />
                        <span className="pl-4">Vouchers</span>
                    </div>
                    {activeTab === 2 ? <KeyboardArrowRightIcon className='p-1'/> : ''}
                </a> */}
                <a
                    style={{ cursor: "pointer" }}
                    onClick={() => setActiveTab(8)}
                    className={`flex items-center justify-between pl-4 py-2  hover:text-black ${activeTab === 8 ? 'bg-gray-200' : ''}`}
                >
                    {/* <ReviewsIcon />
                    <span className="pl-4">Reviews</span> */}
                    <div className='flex items-center '>
                        <PaidIcon />
                        <span className="pl-4">My Purchases</span>
                    </div>
                    {activeTab === 8 ? <KeyboardArrowRightIcon className='p-1'/> : ''}
                </a>
                {/* <a
                    style={{ cursor: "pointer" }}
                    onClick={() => setActiveTab(3)}
                    className={`flex items-center justify-between pl-4 py-2  hover:text-black ${activeTab === 3 ? 'bg-gray-200' : ''}`}
                >
                    
                    <div className='flex items-center '>
                        <ReviewsIcon />
                        <span className="pl-4">Reviews</span>
                    </div>
                    {activeTab === 3 ? <KeyboardArrowRightIcon className='p-1'/> : ''}
                </a> */}
                <hr />
                <a
                    style={{ cursor: "pointer" }}
                    onClick={() => setActiveTab(4)}
                    className={`flex items-center justify-between pl-4 py-2  hover:text-black ${activeTab === 4 ? 'bg-gray-200' : ''}`}
                >
                    {/* <ReviewsIcon /> */}
                    <span className="">Account Management</span>
                    
                </a>
                <a
                    style={{ cursor: "pointer" }}
                    onClick={() => setActiveTab(5)}
                    className={`flex items-center justify-between pl-4 py-2  hover:text-black ${activeTab === 5 ? 'bg-gray-200' : ''}`}
                >
                    {/* <ReviewsIcon /> */}
                    <span className="">Address Book</span>
                    
                </a>
                <hr className='mb-4'/>
                <a
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        logout();
                    }}
                    className={`flex justify-center items-center pl-4 py-2  hover:text-black ${activeTab === 6 ? 'bg-gray-200' : ''}`}
                >
                    {/* <LogoutIcon /> */}
                    <span className="text-red-900">Logout</span>
                </a>
            </div>
                </div>
                <div className="flex flex-col flex-grow bg-white md:mx-4 pt-6 mb-12" style={{ flexBasis: '80%', }}>
                {activeTab == 0 ? 
                    <div className='mx-2 '>
                        
                       <div className='ml-4 flex flex-col'>
                       <div className='mb-4'>Account Overview</div>
                       <hr className='mb-4'/>

                        <div className='pl-4 mb-4 relative'>
                        
                        <img className="object-contain w-32 h-32 p-1 rounded-full ring-2 ring-gray-300" src={photo !== null ? photo : logo} alt=""  //object-contain
                            onMouseEnter={() => {setShowEdit(true)}}
                            onMouseLeave={() => {setShowEdit(false)}}
                            />
                            {
                                showEdit ? <div 
                                onMouseEnter={() => {setShowEdit(true)}}
                                onMouseLeave={() => {setShowEdit(false)}}
                                className="absolute bottom-0 left-28 bg-gray-300 rounded-full p-1 cursor-pointer flex justify-center">
                                    
                                <EditIcon 
                                onClick={() => { document.getElementById('logoInput').click(); }}
                                className='p-1 border rounded-full'
                                />
                            </div> : ""
                            }
                            <input
                    style={{ backgroundColor: '#EDF2F6', width: '100%', 
                    display: 'none' }}
                    type="file"
                    id="logoInput"
                    accept=".jpg, .jpeg, .png"
                    onChange={handlePhotoChange}
                  />
                        </div>

                        

                        <div className='flex w-full md:flex-row flex-col'>
                            <div className="flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4" style={{ flexBasis: '50%' }}>
                                <p className='my-2'>Account Details: {userDetails.Customer_Id}</p>
                                <hr className='mb-4'/>
                                <div className='flex flex-col'>
                                <p className='mb-2'>Name: {userDetails.Firstname} {userDetails.Lastname}</p>
                                <p className='mb-2'>Email: {userDetails.Email}</p>
                                <p className='mb-2'>Phone: {userDetails.Phone_Number}</p>
                                </div>
                            </div>
                            <div className="flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4" style={{ flexBasis: '50%' }}>
                                <div className='flex justify-between items-center'>
                                    <p className='my-2'>Address Book</p>
                                    <EditIcon 
                                    onClick={() => {
                                        setActiveTab(5);
                                    }}
                                    style={{ width: '28px', height: '28px', color: '#000000', cursor: 'pointer' }} className='p-1.5 mr-4 hover:bg-gray-200 hover:rounded-full'/>
                                </div>
                                <hr className='mb-4'/>
                                <p className='mb-2'>Your default shipping address:</p>
                                {
                                    userDetails.Delivery_Address &&
                                
                                <div className='mb-8 ml-4 '>
                                    <p> <strong>City: </strong>{userDetails.Delivery_Address.city} </p>
                                    <p> <strong>State: </strong>{userDetails.Delivery_Address.state} </p>
                                    <p> <strong>Country: </strong>{userDetails.Delivery_Address.country} </p>
                                    <p> <strong>Address 1: </strong>{userDetails.Delivery_Address.address1} </p>
                                    <p> <strong>Address 2: </strong>{userDetails.Delivery_Address.address2} </p>
                                    <p> <strong>Postal Code: </strong>{userDetails.Delivery_Address.postalCode} </p>
                                </div>
                                }

                            </div>
                        </div>
                        <div className='flex w-full md:flex-row flex-col'>
                            <div className="flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4" style={{ flexBasis: '50%' }}>
                                <p className='my-2'>Valor Hijab Credit</p>
                                <hr className='mb-4'/>
                                <p className='mb-8'>Balance: 0</p>
                            </div>
                            <div className="flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4" style={{ flexBasis: '50%' }}>
                                <p className='my-2'>Newsletters</p>
                                <hr className='mb-4'/>
                                <p className='mb-8'>No current subscriptions</p>
                            </div>
                        </div>

                       </div>
                    </div>
                    :
                    activeTab == 1 ? 
                    <div className='mx-2 '>
                        <div className='ml-4 flex flex-col'>
                       <div className='mb-4'>My Collections</div>
                       <hr className='mb-4'/>



                       <div className="w-full pb-8">

</div>




                        

                       </div>
                    </div>
                    :
                    activeTab == 2 ? 
                    <div className='mx-2 '>
                        
                       <div className='ml-4 flex flex-col'>
                       <div className='mb-4'>My Vouchers</div>
                       <hr className='mb-4'/>
                        
                        

                       </div>
                    </div>
                    :
                    activeTab == 3 ? 
                    <div className='mx-2 '>
                        
                       <div className='ml-4 flex flex-col'>
                       <div className='mb-4'>My Reviews</div>
                       <hr className='mb-4'/>
                        
                        

                       </div>
                    </div>
                    :
                    activeTab == 4 ?
                    <div className='mx-2 '>
                        
                       <div className='ml-4 flex flex-col'>
                       <div className='mb-4'>Account Management</div>
                       <hr className='mb-4'/>

                       <div className='flex w-full md:flex-row flex-col'>
                            <div className="flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4" style={{ flexBasis: '50%' }}>
                                <div className='flex justify-between items-center'>
                                    <p className='my-2'>Profile Details</p>
                                    <AccountCircleIcon 
                                    onClick={() => {
                                        
                                    }}
                                    style={{ width: '28px', height: '28px', color: '#000000', cursor: 'pointer' }} className='p-1.5 mr-4 hover:bg-gray-200 hover:rounded-full'/>
                                </div>
                                <hr className='mb-4'/>
                                <div className='flex flex-col'>
                                {/* <p className='mb-8'>Basic Details</p>
                                <p className='mb-8'>Edit Phone Number</p> */}
<div className="flex flex-wrap mr-4 ">
                                        <div className="w-full md:w-1/1 px-2 mb-4 ">
                                            <label htmlFor="" className="block text-sm font-medium text-gray-700">Firstname:</label>
                                            <input type="text" id="userFirstname" name="firstname"
                                            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 
                                            block w-full p-2.5" placeholder='Firstname' 
                                            value={userData.userFirstname}//userDetails.Firstname} 
                                            onChange={(e) => setUserData({ ...userData, userFirstname: e.target.value })}
                                            />
                                        </div>

                                    </div>

<div className="flex flex-wrap mr-4 ">

                                        <div className="w-full md:w-1/1 px-2 mb-4">
                                            <label htmlFor="userLastname" className="block text-sm font-medium text-gray-700">Lastname:</label>
                                            <input type="text" id="userLastname" name="lastname"
                                            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 
                                            block w-full p-2.5" placeholder='Lastname'   
                                            value={userData.userLastname}//userDetails.Lastname}
                                            onChange={(e) => setUserData({ ...userData, userLastname: e.target.value })}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap  mr-4">
                                        <div className="w-full md:w-1/1 px-2 mb-4">
                                            <label htmlFor="" className="block text-sm font-medium text-gray-700">Email:</label>
                                            <input type="text" id="userEmail" name="email"
                                            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 
                                            block w-full p-2.5" placeholder='Email'  readOnly={true}
                                            value={userData.userEmail}//userDetails.Email} 
                                            onChange={(e) => setUserData({ ...userData, userEmail: e.target.value })}
                                            />
                                        </div>

                                    </div>

                                    <div className="flex flex-wrap mr-4">
                                        <div className="w-full md:w-1/1 px-2 mb-4">
                                            <label htmlFor="" className="block text-sm font-medium text-gray-700">PhoneNumber:</label>
                                            <input type="text" id="userPhoneNumber" name="email"
                                            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 
                                            block w-full p-2.5" placeholder='Phone Number' 
                                            value={userData.userPhoneNumber}//userDetails.PhoneNumber} 
                                            onChange={(e) => setUserData({ ...userData, userPhoneNumber: e.target.value })}
                                            />
                                        </div>

                                    </div>

                                    <hr className='mb-4'/>
                                 {
                                    
                                    <div  
                                    onClick={() => updateCustomerAccountDetails() }
                    style={{ }} className="flex items-center  px-4 py-2 bg-valorHijabTheme cursor-pointer mb-4 ml-2 mr-6 ">
                      <AddIcon style={{ color: '#ffffff', borderRadius: '0px'}} className="mr-2 " />
                      <div className="text-s " style={{color: '#ffffff'}}>{isDataloading ? "Please wait.." : "Update Account"}</div>
                    </div>
                                    
                                 }





                                </div>
                            </div>
                            <div className="flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4" style={{ flexBasis: '50%' }}>
                                <div className='flex justify-between items-center'>
                                    <p className='my-2'>Security Settings</p>
                                    <LockIcon 
                                    onClick={() => {
                                        
                                    }}
                                    style={{ width: '28px', height: '28px', color: '#7F1D1D', cursor: 'pointer' }} className='p-1.5 mr-4 hover:bg-gray-200 hover:rounded-full'/>
                                </div>
                                <hr className='mb-4'/>
                                {/* <p className='mb-8'>Manage Passkeys</p> */}
                                <p className='mb-8 cursor-pointer' onClick={() => { setActiveTab(9); }}>Change Password</p>
                                <p className='mb-8 cursor-pointer' onClick={() => { setActiveTab(9); }}>Pin Setting</p>
                            </div>
                        </div>
                        
                        

                       </div>
                    </div>
                    :
                    activeTab == 5 ?
                    <div className='mx-2'>
                        <div className='mx-2'>
                        <div className='mx-2 '>
                        
                        <div className='ml-4 flex flex-col'>
                        <div className='mb-4 cursor-pointer'>Address Book</div>
                        {/* <div className='mb-4 cursor-pointer flex justify-between'>
                            <div>Address Book</div><div className='mr-4'>Add New Address</div>
                        </div> */}
                        <hr className='mb-4'/>
                     
 
                         <div className='flex w-full md:flex-row flex-col'>
                             <div className="flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4" style={{ flexBasis: '50%' }}>                                 
                                 <div className='flex flex-col my-2'>
                                 <p className='mb-2'>Default Shipping Address</p>
                                 {
                                    userDetails.Delivery_Address &&
                                    <div className='mb-8 ml-4 '>
                                        <p> <strong>City: </strong>{userDetails.Delivery_Address.city} </p>
                                        <p> <strong>State: </strong>{userDetails.Delivery_Address.state} </p>
                                        <p> <strong>Country: </strong>{userDetails.Delivery_Address.country} </p>
                                        <p> <strong>Street Address: </strong>{userDetails.Delivery_Address.address1} </p>
                                        <p> <strong>(optional) Address: </strong>{userDetails.Delivery_Address.address2} </p>
                                        <p> <strong>Postal Code: </strong>{userDetails.Delivery_Address.postalCode} </p>
                                    </div>
                                 }
                                 
                                 </div>
                                 <hr className='mb-4'/>
                                 <div className='flex justify-between items-center'>
                                     <p className='my-2 cursor-pointer' 
                                    //  onClick={() => setAsDefault(0)}
                                        >{isDataloading ? "Please wait.." : "Set As Default"}</p>
                                     <EditIcon 
                                     onClick={() => { 
                                      setSelectedAddress(userDetails.Delivery_Address); 
                                      setSelectedAddressIndex(0); 
                                      setActiveTab(7);
                                    }}
                                     style={{ width: '28px', height: '28px', color: '#000000', cursor: 'pointer' }} className='p-1.5 mr-4 hover:bg-gray-200 hover:rounded-full'/>
                                 </div>                                 
                             </div>
                             {/* <div className="bg-gray-100 flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4" style={{ flexBasis: '50%' }}>                                 
                                 <div className='flex flex-col my-2'>
                                 <p className='mb-2'>Address 2</p>
                                 { 
                                    addresses && addresses[1] && addresses[1].deliveryAddress &&
                                    <div className='mb-8 ml-4 '>
                                        <p> <strong>City: </strong>{addresses[1].deliveryAddress.city} </p>
                                        <p> <strong>State: </strong>{addresses[1].deliveryAddress.state} </p>
                                        <p> <strong>Country: </strong>{addresses[1].deliveryAddress.country} </p>
                                        <p> <strong>Street Address: </strong>{addresses[1].deliveryAddress.address1} </p>
                                        <p> <strong>(optional) Address: </strong>{addresses[1].deliveryAddress.address2} </p>
                                        <p> <strong>Postal Code: </strong>{addresses[1].deliveryAddress.postalCode} </p>
                                    </div>
                                 }
                                 </div>
                                 <hr className='mb-4'/>
                                 <div className='flex justify-between items-center'>
                                     <p className='my-2 cursor-pointer' onClick={() => setAsDefault(1)}>{isDataloading ? "Please wait.." : "Set As Default"}</p>
                                     <EditIcon 
                                     onClick={() => { setSelectedAddress((addresses && addresses[1] && addresses[1].deliveryAddress) || addressEmpty); setSelectedAddressIndex(1); setActiveTab(7);}}
                                     style={{ width: '28px', height: '28px', color: '#000000', cursor: 'pointer' }} className='p-1.5 mr-4 hover:bg-gray-200 hover:rounded-full'/>
                                 </div>                                 
                             </div> */}
                         </div>
                         {/* <div className='flex w-full md:flex-row flex-col'>
                             <div className="bg-gray-100 flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4" style={{ flexBasis: '50%' }}>                                 
                                 <div className='flex flex-col my-2'>
                                 <p className='mb-2'>Address 3</p>
                                 { 
                                    addresses && addresses[2] && addresses[2].deliveryAddress &&
                                    <div className='mb-8 ml-4 '>
                                        <p> <strong>City: </strong>{addresses[2].deliveryAddress.city} </p>
                                        <p> <strong>State: </strong>{addresses[2].deliveryAddress.state} </p>
                                        <p> <strong>Country: </strong>{addresses[2].deliveryAddress.country} </p>
                                        <p> <strong>Street Address: </strong>{addresses[2].deliveryAddress.address1} </p>
                                        <p> <strong>(optional) Address: </strong>{addresses[2].deliveryAddress.address2} </p>
                                        <p> <strong>Postal Code: </strong>{addresses[2].deliveryAddress.postalCode} </p>
                                    </div>
                                 }
                                 </div>
                                 <hr className='mb-4'/>
                                 <div className='flex justify-between items-center'>
                                     <p className='my-2 cursor-pointer' onClick={() => setAsDefault(2)}>{isDataloading ? "Please wait.." : "Set As Default"}</p>
                                     <EditIcon 
                                     onClick={() => { setSelectedAddress((addresses && addresses[2] && addresses[2].deliveryAddress) || addressEmpty); setSelectedAddressIndex(2); setActiveTab(7);}}
                                     style={{ width: '28px', height: '28px', color: '#000000', cursor: 'pointer' }} className='p-1.5 mr-4 hover:bg-gray-200 hover:rounded-full'/>
                                 </div>                                 
                             </div>
                             <div className="bg-gray-100 flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4" style={{ flexBasis: '50%' }}>                                 
                                 <div className='flex flex-col my-2'>
                                 <p className='mb-2'>Address 4</p>
                                 { 
                                    addresses && addresses[3] && addresses[3].deliveryAddress &&
                                    <div className='mb-8 ml-4 '>
                                        <p> <strong>City: </strong>{addresses[3].deliveryAddress.city} </p>
                                        <p> <strong>State: </strong>{addresses[3].deliveryAddress.state} </p>
                                        <p> <strong>Country: </strong>{addresses[3].deliveryAddress.country} </p>
                                        <p> <strong>Street Address: </strong>{addresses[3].deliveryAddress.address1} </p>
                                        <p> <strong>(optional) Address: </strong>{addresses[3].deliveryAddress.address2} </p>
                                        <p> <strong>Postal Code: </strong>{addresses[3].deliveryAddress.postalCode} </p>
                                    </div>
                                 }
                                 </div>
                                 <hr className='mb-4'/>
                                 <div className='flex justify-between items-center'>
                                     <p className='my-2 cursor-pointer' onClick={() => setAsDefault(3)}>{isDataloading ? "Please wait.." : "Set As Default"}</p>
                                     <EditIcon 
                                     onClick={() => { setSelectedAddress((addresses && addresses[3] && addresses[3].deliveryAddress) || addressEmpty); setSelectedAddressIndex(3); setActiveTab(7);}}
                                     style={{ width: '28px', height: '28px', color: '#000000', cursor: 'pointer' }} className='p-1.5 mr-4 hover:bg-gray-200 hover:rounded-full'/>
                                 </div>                                 
                             </div>
                         </div> */}
 
                        </div>
                     </div>
                    </div>
                    </div>
                : activeTab == 6 ? //logout
                <div className='mx-2'>6
                </div>
                : activeTab == 7 ? //edit address
                <div className='mx-2'>
                        <div className='mx-2'>
                        <div className='mx-2 '>
                        
                        <div className='ml-4 flex flex-col'>
                            {/* proprietary rbapps */}
                        <div className='mb-4 items-center flex cursor-pointer' 
                        onClick={() => {setActiveTab(5); handleLeaveWestNewIn(); }}
                        // onMouseEnter={handleHoverWestNewIn}
        // onMouseLeave={handleLeaveWestNewIn}
        >
            <ArrowRightAltIcon
        // className='cursor-pointer'
        // onClick={() => setActiveTab(5)}
        onMouseEnter={handleHoverWestNewIn}
        onMouseLeave={handleLeaveWestNewIn}
        style={{ width: isHoverWestNewIn ? '32px' : '44px', transition: 'width 0.3s ease', transform: 'scaleX(-1)' }}
      />Edit Address {(selectedAddressIndex + 1)}</div>{/* proprietary rbapps */}
      {/* {(JSON.stringify(selectedAddress, null, 2))} */}
                        <hr className='mb-4'/>
                     
 
                        <div className='flex flex-wrap w-full'>
    <div className="flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4 w-full md:w-1/2">
    <div className="grid grid-cols-1 gap-y-6 gap-x-4 px-4 py-5 sm:grid-cols-3 sm:px-6">
                    
                    <div>
                        <label htmlFor="billing_address_1" className="block text-sm font-medium text-gray-700">
                            Street address
                        </label>
                        <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                            placeholder="Street address"
                            maxLength={30}
                            onChange={(e) => 
                                {
                                    setAddress({ ...address, address1: e.target.value });
                                    setAddress1(e.target.value);
                                }
                            }
                            // onChange={handleChange}
                            value={address.address1}
                        />
                    </div>
                    <div>
                        <label htmlFor="billing_address_2" className="block text-sm font-medium text-gray-700">
                            (optional) Address
                        </label>
                        <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                            placeholder="Apartment, Suite, Unit etc."
                            maxLength={30}
                            onChange={(e) => 
                                {
                                    setAddress({ ...address, address2: e.target.value });
                                    setAddress2(e.target.value);
                                }
                            }
                            
                            // onChange={handleInputChange}
                            value={address.address2}
                        />
                    </div>
                    <div>
                        <label htmlFor="billing_city" className="block text-sm font-medium text-gray-700">
                            City
                        </label>
                        <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                            placeholder="City"
                            maxLength={20}
                            onChange={(e) => 
                                {
                                    setAddress({ ...address, city: e.target.value });
                                    setCity(e.target.value);
                                }
                        }
                            // onChange={handleInputChange}
                            value={address.city}
                        />
                    </div>
                    <div>
                        <label htmlFor="billing_postcode" className="block text-sm font-medium text-gray-700">
                            Postcode / ZIP
                        </label>
                        <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                            placeholder="Postalcode"
                            maxLength={10}
                            onChange={(e) => 
                                {
                                    setAddress({ ...address, postalCode: e.target.value });
                                    setPostalCode(e.target.value);
                                }
                            }
                            // onChange={handleInputChange}
                            value={address.postalCode}
                        />
                    </div>
                    <div>
                        <label htmlFor="billing_state" className="block text-sm font-medium text-gray-700">
                            State
                        </label>
                        <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                            placeholder="State"
                            maxLength={20}
                            onChange={(e) => 
                                {
                                    setAddress({ ...address, state: e.target.value });
                                    setState(e.target.value);
                                }
                            }
                            // onChange={handleInputChange}
                            value={address.state}
                        />
                    </div>
                    <div>
                        <label htmlFor="billing_country" className="block text-sm font-medium text-gray-700">
                            Country
                        </label>
                        <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 "
                            placeholder="Country"
                            maxLength={20}
                            onChange={(e) => 
                                {
                                    setAddress({ ...address, country: e.target.value });
                                    setCountry(e.target.value);
                                }
                            }
                            // onChange={handleInputChange}
                            value={address.country}
                        />
                    </div>

                    
                </div>
                
                

                <hr className='mb-4'/>
                                 {
                                    
                                    <div  
                                    onClick={() => updateCustomerDeliveryAddressIndex(selectedAddressIndex) }
                    style={{ }} className="flex items-center  px-4 py-2 bg-valorHijabTheme cursor-pointer mb-4">
                      <AddIcon style={{ color: '#ffffff', borderRadius: '0px'}} className="mr-2 " />
                      <div className="text-s " style={{color: '#ffffff'}}>{isDataloading ? "Please wait.." : "Update Address"}</div>
                    </div>
                                    
                                 }

    </div>


    
</div>

                         
 
                        </div>
                     </div>
                    </div>
                    </div>
                : activeTab == 8 ? //my purchases
                <div className='mx-2'>
                        <div className='mx-2'>
                        <div className='mx-2 '>
                        
                        <div className='ml-4 flex flex-col'>
                            {/* proprietary rbapps */}
                        <div className='mb-4 items-center flex cursor-pointer' 
                        onClick={() => {setActiveTab(0); handleLeaveWestNewIn();}}
                        // onMouseEnter={handleHoverWestNewIn}
        // onMouseLeave={handleLeaveWestNewIn}
        >
            <ArrowRightAltIcon
        // className='cursor-pointer'
        // onClick={() => setActiveTab(5)}
        onMouseEnter={handleHoverWestNewIn}
        onMouseLeave={handleLeaveWestNewIn}
        style={{ width: isHoverWestNewIn ? '32px' : '44px', transition: 'width 0.3s ease', transform: 'scaleX(-1)' }}
      />Purchases</div>{/* proprietary rbapps */}
                        <hr className='mb-4'/>
                     
                        <div className='flex w-full bg-gray-100  z-20'>
      <div className="w-full">
        <div className=''>
          <div className=''>
            <div className="">
              <div className="flex flex-col overflow-x-auto">
                <div className="">
                {isUserOrdersDataLoading ? 
                
                <Loading  type={'orders'}/>
                : 
                  <div className="inline-block min-w-full py-2 sm:px-2 lg:px-0">
                    <div className="overflow-x-auto">
                      <div className='overflow-x-auto  '>
                        <table className='min-w-full bg-white shadow-md overflow-hidden'>
                          <thead>
                            <tr>
                              <th className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 text-black uppercase tracking-wider'>
                                SN
                              </th>
                              <th className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 text-black uppercase tracking-wider'>
                                Created On
                              </th>
                              {/* <th className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 text-black uppercase tracking-wider'>
                                Username
                              </th> */}
                              {/* <th className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 text-black uppercase tracking-wider'>
                                Firstname
                              </th> */}
                              {/* <th className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 text-black uppercase tracking-wider'>
                                Lastname
                              </th> */}
                              {/* <th className='px-6 py-3 border-b border-gray-300 text-left text-xs leading-4 text-black uppercase tracking-wider'>
                                Email
                              </th>                               */}
                              <th className='px-6 py-3 border-b border-gray-300 text-right text-xs leading-4 text-black uppercase tracking-wider'>
                                Delivery Address
                              </th>
                              <th className='px-6 py-3 border-b border-gray-300 text-right text-xs leading-4 text-black uppercase tracking-wider'>
                                Orders
                              </th>
                              <th className='px-6 py-3 border-b border-gray-300 text-right text-xs leading-4 text-black uppercase tracking-wider'>
                                Price
                              </th>
                              {/* <th className='px-6 py-3 border-b border-gray-300 text-right text-xs leading-4 text-black uppercase tracking-wider'>
                                Tax Id
                              </th> */}
                              <th className='px-6 py-3 border-b border-gray-300 text-right text-xs leading-4 text-black uppercase tracking-wider'>
                                Payment Method
                              </th>
                              <th className='px-6 py-3 border-b border-gray-300 text-right text-xs leading-4 text-black uppercase tracking-wider'>
                                Order Status
                              </th>
                            </tr>
                          </thead>

                          
                          <tbody>
                            {/* {currentFilteredProducts.map((order) => ( */}
                            {userOrdersData.map((order) => (
                              <tr key={order.id}>
                                <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                                  {/* {countFiltered++} */}
                                  {order.Id}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                  {order.Created_On}
                                </td>
                                {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 cursor-pointer" 
                                // onClick={(e) => handleProductClick(order, e)}
                                >
                                  {order.Username}
                                </td> */}
                                {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">                                  
                                  {order.Firstname}
                                </td> */}
                                {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                  {order.Lastname}
                                </td> */}
                                {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                  {order.Email}
                                </td> */}
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-right">
                                  {/* {product.Delivery_Address.join(', ')} array */}
                                  {Object.values(order.Delivery_Address).filter(Boolean).join(', ')}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-right" 
                                  dangerouslySetInnerHTML={{
                                  __html: order.Orders.map(order => `${order.productName} (${order.selectedColor}) x${order.quantity}`).filter(Boolean).join(',<br/>')
                                  }} 
                                />
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-right">
                                  {order.Price}
                                </td>
                                {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-right">
                                  {order.Tax_Id}
                                </td> */}
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-right">
                                  {order.Payment_Method}
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-right">
                                  {order.Order_Status}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          
                        </table>
                      </div>

                      {/* Pagination Controls */}
                      {/* <div className="flex justify-center mt-4 mb-4">
                        <button
                          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                          disabled={currentPage === 1}
                          className="px-4 py-2 mr-2 bg-gray-300 hover:bg-valorHijabTheme hover:text-white transition-colors duration-300 cursor-pointer"
                        >
                          {'<'}
                        </button>
                        <button
                          onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalFilteredPages))}
                          disabled={currentPage === totalFilteredPages}
                          className="px-4 py-2 bg-gray-300 hover:bg-valorHijabTheme hover:text-white transition-colors duration-300 cursor-pointer"
                        >
                          {'>'}
                        </button>
                      </div> */}
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
 
                         
 
                        </div>
                     </div>
                    </div>
                    </div> : 
                    activeTab == 9 ? //change password
                    <div className='mx-2'>
                            <div className='mx-2'>
                            <div className='mx-2 '>
                            
                            <div className='ml-4 flex flex-col'>
                                {/* proprietary rbapps */}
                            <div className='mb-4 items-center flex cursor-pointer' 
                            onClick={() => {setActiveTab(4); handleLeaveWestNewIn();}}
                            // onMouseEnter={handleHoverWestNewIn}
            // onMouseLeave={handleLeaveWestNewIn}
            >
                <ArrowRightAltIcon
            // className='cursor-pointer'
            // onClick={() => setActiveTab(5)}
            onMouseEnter={handleHoverWestNewIn}
            onMouseLeave={handleLeaveWestNewIn}
            style={{ width: isHoverWestNewIn ? '32px' : '44px', transition: 'width 0.3s ease', transform: 'scaleX(-1)' }}
          />Change Password</div>{/* proprietary rbapps */}
                            <hr className='mb-4'/>
                         
                            
                            <div className='flex w-full md:flex-row flex-col'>
                            <div className="flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4" style={{ flexBasis: '50%' }}>
                                <div className='flex justify-between items-center'>
                                    <p className='my-2'>.</p>
                                    <LockIcon 
                                    onClick={() => {
                                        
                                    }}
                                    style={{ width: '28px', height: '28px', color: '#000000', cursor: 'pointer' }} className='p-1.5 mr-4 hover:bg-gray-200 hover:rounded-full'/>
                                </div>
                                <hr className='mb-4'/>
                                <div className='flex flex-col'>
                                {/* <p className='mb-8'>Basic Details</p>
                                <p className='mb-8'>Edit Phone Number</p> */}
<div className="flex flex-wrap mr-4 ">
                                        <div className="w-full md:w-1/1 px-2 mb-4 ">
                                            <label htmlFor="oldPassword" className="block text-sm font-medium text-gray-700">Old Password:</label>
                                            <input type="password" id="oldPassword" name="oldPassword"
                                            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 
                                            block w-full p-2.5" placeholder='Old Password' 
                                            value={userData.userOldPassword} 
                                            onChange={(e) => setUserData({ ...userData, userOldPassword: e.target.value })}
                                            />
                                            {/* <div className="absolute inset-y-0 right-0 flex items-center pr-2 mt-1 mr-2" 
  >
    <div tabIndex="-1" 
    onClick={() => setOldPasswordVisible(!oldPasswordVisible)}
    >
      {oldPasswordVisible ? <VisibilityOff className='p-1'/> : <Visibility className='p-1'/>}
    </div>
  </div> */}
                                        </div>

                                    </div>


                                    <div className="flex flex-wrap mr-4">
            <div className="w-full md:w-1/1 px-2 mb-4 relative"> {/* Add relative class here */}
                <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                New Password:
                </label>
                <input
                    type={newPasswordVisible ? "text" : "password"} // Toggle password visibility
                    id="newPassword"
                    name="newPassword"
                    className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 pr-10" // Add padding to the right for the icon
                    placeholder='Confirm Password'
                    value={userData.userNewPassword}
                    onChange={(e) => setUserData({ ...userData, userNewPassword: e.target.value })}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-4 mt-4">
                    <div
                        tabIndex="-1"
                        onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                        className="cursor-pointer" // Add cursor pointer for better UX
                    >
                        {newPasswordVisible ? (
                            <VisibilityOff className='p-1' />
                        ) : (
                            <Visibility className='p-1' />
                        )}
                    </div>
                </div>
            </div>
        </div>

                                    <div className="flex flex-wrap mr-4">
            <div className="w-full md:w-1/1 px-2 mb-4 relative"> {/* Add relative class here */}
                <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                    Confirm Password:
                </label>
                <input
                    type={confirmPasswordVisible ? "text" : "password"} // Toggle password visibility
                    id="confirmPassword"
                    name="confirmPassword"
                    className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 pr-10" // Add padding to the right for the icon
                    placeholder='Confirm Password'
                    value={userData.userConfirmPassword}
                    onChange={(e) => setUserData({ ...userData, userConfirmPassword: e.target.value })}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-4 mt-4">
                    <div
                        tabIndex="-1"
                        onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                        className="cursor-pointer" // Add cursor pointer for better UX
                    >
                        {confirmPasswordVisible ? (
                            <VisibilityOff className='p-1' />
                        ) : (
                            <Visibility className='p-1' />
                        )}
                    </div>
                </div>
            </div>
        </div>


                                    <hr className='mb-4'/>
                                 {
                                    
                                    <div  
                                    onClick={() => updatePassword() }
                    style={{ }} className="flex items-center  px-4 py-2 bg-valorHijabTheme cursor-pointer mb-4 ml-2 mr-6">
                      <AddIcon style={{ color: '#ffffff', borderRadius: '0px'}} className="mr-2 " />
                      <div className="text-s " style={{color: '#ffffff'}}>{isDataloading ? "Please wait.." : "Update Password"}</div>
                    </div>
                                    
                                 }





                                </div>
                            </div>
                            <div className="flex flex-col flex-grow border border:bg-black mx-4 mt-2 mb-12 pl-4" style={{ flexBasis: '50%' }}>
                                <div className='flex justify-between items-center'>
                                    <p className='my-2'>.</p>
                                    <LockIcon 
                                    onClick={() => {
                                        
                                    }}
                                    style={{ width: '28px', height: '28px', color: '#000000', cursor: 'pointer' }} className='p-1.5 mr-4 hover:bg-gray-200 hover:rounded-full'/>
                                </div>
                                <hr className='mb-4'/>
                                {/* <p className='mb-8'>Manage Passkeys</p> */}
                                <p className='mb-8'>Pin Setting</p>
                                <p className='mb-8 text-red-900'>Delete Account</p>
                            </div>
                        </div>
     
                             
     
                            </div>
                         </div>
                        </div>
                        </div> : 
                    ''
                
                }


                </div>
            </div>
        </div>








        <NotificationModal
              isOpen={isNotificationModalOpen}
              onRequestClose={closeNotificationModal}
              notificationType={notificationType}
              notificationTitle={notificationTitle}
              notificationMessage={notificationMessage}
            />
    </div>
    );
}