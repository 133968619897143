import React, { Fragment, useState, useEffect } from 'react';


import CalculatedShipping from "./CalculatedShipping";
// import SimilarProducts from './SimilarProducts';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import './react-css/cart.css';

import CryptoJS from 'crypto-js';
import { AES } from 'crypto-js';

import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


import Header from './Header';
import Footer from './Footer';

import { FormattedMessage, IntlProvider } from 'react-intl';
import Messages from '../widget/Messages'; // Import your language messages

function CartPage({ options, setSiteLanguage, addToCart, updateCart, removeCartItem, removeAllCartItem, clearCart, selectedLanguage }) {
    const navigate = useNavigate();
    
    const location = useLocation();
    const cart = location.state.encryptedData;
    const decryptedData = AES.decrypt(decodeURIComponent(cart), 'encryptionKey').toString(CryptoJS.enc.Utf8);
    const [parsedCart, setParsedCart] = useState(JSON.parse(decryptedData)); // State to store parsed cart

    // const selectedLanguage = location.state.selectedLanguage;

    const [products, setProductsData] = useState([]);
    const [cartItems, setCartItems] = useState(parsedCart); // Define cartItems state variable
    // alert(JSON.stringify(cartItems, null, 2));

    const [isDataloading, setIsDataLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        
        
        //handleData();
    }, []);

    useEffect(() => {
        // Update parsedCart when cartItems change
        setParsedCart(cartItems);
    }, [cartItems]);

    const handleData = async () => {
        setIsDataLoading(true);
        try {
            const response = await axios.get(process.env.REACT_APP_API_SERVER_URL +'/api/v1/products', {
                headers: {
                    "Content-Type": "application/json",
                },
            });

            setIsDataLoading(false);
            if (response.data.success) {
                setProductsData(response.data.products);
            } else {
                //alert("error: " + response.data.message);
            }
        } catch (error) {
            setIsDataLoading(false);
            //alert("error: " + error);
        }
    };

    const handleIncreaseQuantity = (item) => {
        const updatedCart = cartItems.map((cartItem) =>
        cartItem.Id === item.Id && cartItem.selectedColor === item.selectedColor ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
      );
      setCartItems(updatedCart);
      localStorage.setItem('valor-hijab-cart', JSON.stringify(updatedCart));
      
      updateCart();
      calculateCartSubTotal();
      };
    
      const handleDecreaseQuantity = (item) => {
        if (item.quantity > 1) {
          const updatedCart = cartItems.map((cartItem) =>
            cartItem.Id === item.Id && cartItem.selectedColor === item.selectedColor ? { ...cartItem, quantity: cartItem.quantity - 1 } : cartItem
          );
          setCartItems(updatedCart);
          localStorage.setItem('valor-hijab-cart', JSON.stringify(updatedCart));
        } else {
          // If quantity reaches 1 and the selected color is the only one, remove the color from the cart
          const updatedCart = cartItems.map((cartItem) =>
            cartItem.Id === item.Id && cartItem.selectedColor === item.selectedColor
                ? { ...cartItem, quantity: 0 } // Set quantity to 0 to indicate it should be removed
                : cartItem
        ).filter((cartItem) => cartItem.quantity > 0); // Remove items with quantity 0
        setCartItems(updatedCart);
        localStorage.setItem('valor-hijab-cart', JSON.stringify(updatedCart));
        }
        updateCart();
        calculateCartSubTotal();
      };

    // Function to find the lowest price among product variants
    function findLowestPrice(product) {
        // let lowestPrice = Infinity;
        // product.productVariants.forEach(variant => {
        //     if (variant.price < lowestPrice) {
        //         lowestPrice = variant.price;
        //     }
        // });
        // return lowestPrice;
        return product.Price;
    }

    const calculateTotal = (item) => {
        return findLowestPrice(item) * item.quantity;
    };

    const calculateCartSubTotal = () => {
        let subTotal = 0;
        cartItems.forEach((item) => {
            subTotal += findLowestPrice(item) * item.quantity;
        });
        return subTotal;
    };

    const calculateCartTax = () => {
        let subTotal = 0;
        parsedCart.forEach((item) => {
            subTotal += findLowestPrice(item) * item.quantity;
        });
    
        const taxRate = 0.025; // 2.5%
        const calculatedTax = subTotal * taxRate;
        
        return calculatedTax;
    };

    let countCartItem = 1;

    const calculateShipping = () => {
        const shipping = calculateCartSubTotal() < 50.00 ? 5.00 : 0.00;
        return shipping;
    };

    function mainProductImage(product) { 
        // return product.productImages.find(img => img.isDefault).url;
        return product.Image_Url;
      }

    return (
        <div className='relative  bg-valorHijabTheme ' style={{  }}>
            <Header options={options} cart={parsedCart} setSiteLanguage={setSiteLanguage} addToCart={addToCart} updateCart={updateCart} removeCartItem={removeCartItem} removeAllCartItem={removeAllCartItem}/>


            <img 
    src={'https://www.valorhijab.com/api/v1/vh-images/Pleated.jpeg'} //{process.env.REACT_APP_API_SERVER_URL + "/vh-images/" + parsedProduct.Image_Url}
    alt='overlay' 
    className='absolute inset-0 w-full h-full object-cover opacity-20' 
  />



            <div className="mx-8 my-8   bg-white overflow-hidden">

            {/* <div className="flex flex-col md:flex-row" style={{  }}> */}
                <div className='cart-section woocommerce-cart'>

                {/* <div className="w-full  py-4"> */}
                <div className="woocommerce  mx-4 my-4">



                <table className="shop_table shop_table_responsive cart">
                                        <thead>
                                            <tr>
                                                <th className="product-remove">s/n</th>
                                                <th className="product-thumbnail flex justify-center">Image</th>
                                                <th className="product-name">Product</th>
                                                <th className="product-price text-right">Price</th>
                                                <th className="product-quantity text-center">Quantity</th>
                                                <th className="product-quantity text-left">Color</th>
                                                <th className="product-subtotal text-right">Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cartItems.map((cartItem) => (
                                                <tr key={cartItem.id} style={{ cursor: "pointer" }}>
                                                    <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                                                        {countCartItem++}
                                                    </td>
                                                    <td className='px-6 py-4 whitespace-no-wrap border-b border-gray-200'>
                                                        <div className='flex justify-center'>
                                                            <img className='md:w-full' 
                                                            // src={mainProductImage(cartItem)}
                                                            src={process.env.REACT_APP_API_SERVER_URL + "/vh-images/" + mainProductImage(cartItem)}
                                                            />
                                                        </div>                                                        
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                                                        {cartItem.Item_Name}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-right">
                                                    {'$'}{findLowestPrice(cartItem).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-center">
                                                        <div className="touchspin-wrap" style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div className='flex bg-white items-center justify-center m-2' style={{ height: '80%', width: '100%' }}>
                                                                <RemoveIcon className='' style={{ cursor: 'pointer', width: '30px', borderRight: '1px solid #ccc' }} 
                                                                onClick={() => { handleDecreaseQuantity(cartItem) }}
                                                                />
                                                                <span className='flex justify-center items-center text-center' style={{ width: '30px' }}>{cartItem.quantity}</span>
                                                                <AddIcon className='' style={{ cursor: 'pointer', width: '30px', borderLeft: '1px solid #ccc' }} 
                                                                onClick={() => { handleIncreaseQuantity(cartItem) }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                    <div 
                                                    style={{
                                width: '24px',
                                height: '24px',
                                backgroundColor: cartItem.selectedColor,  // Display the actual color
                                borderRadius: '25%',     // Perfect circle for the color
                              }}></div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-right">
                                                    {'$'}{calculateTotal(cartItem).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="cart-collaterals">
                                        <CalculatedShipping currencySymbol={'$'} price={calculateCartSubTotal()} tax={calculateCartTax()} shipping={calculateShipping()} options={options} cart={parsedCart}/>
                                    </div>
                    
                    
                </div>
            </div>

            </div>



            {/* <SimilarProducts onQuickViewClick={handleDataViewData} products={products} addToCart={addToCart} selectedLanguage={selectedLanguage}/> */}


            <Footer />
        </div>
        
    );
}

export default CartPage;